<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con" id="page_con">
          <div class="page_tab">
            <div class="btn_wrap">
              <button @click="fnMove()">
                참여파티</button
              ><!--'on'-->
              <button class="on">
                생성파티
              </button>
            </div>
          </div>
          <SearchConditions
            :conditionOpen="conOpen"
            :conditionsList="conditionsList"
            :type="'PARTY'"
            :height="'h190'"
            :autoExtension="autoExtension"
            @update="fnUpdateConditionsList($event)"
            @updateAutoExtension="fnUpdateAutoExtension($event)"
            @updateOnOff="fnUpdateOnOffConditions($event)"
          />
          <div class="lump bo_zero">
            <label class="input srh_type02">
              <input
                type="search"
                placeholder="검색어를 입력해 주세요."
                @input="inputSearchWord"
                :value="searchWord"
                @keyup.enter="fnSearch"
              />
              <button
                v-if="searchWord"
                class="btn_search delete"
                @click="() => (searchWord = '')"
              ></button>
              <button class="btn_search" @click="fnSearch">검색</button>
            </label>
          </div>
          <div class="party_list_wrap">
            <div class="list_top">
              <div class="left">
                <button class="total">총 {{ totalCnt }}건</button>
              </div>
              <div class="right">
                <!-- 211223 추가 -->
                <button
                  class="li_btn up"
                  :class="{ on: order === 'ASC' }"
                  @click="() => (order = 'ASC')"
                ></button>
                <button
                  class="li_btn down"
                  :class="{ on: order === 'DESC' }"
                  @click="() => (order = 'DESC')"
                ></button>
                <!-- 211223 추가 끝 -->
                <label class="select">
                  <select v-model="sort">
                    <option :value="'REGDT'">등록순</option>
                    <option :value="'DEADLINE'">마감순</option>
                    <option :value="'MODDT'">업데이트순</option>
                    <option :value="'AMT'">금액순</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="party_list row_type" v-if="!$Util.isEmpty(list)">
              <!--'가로형: row_type'-->
              <ul class="party_list_ul">
                <li
                  class="party_list_li type_02"
                  :class="{
                    disabled:
                      item.status === '종료' || item.status === '자체마감'
                  }"
                  v-for="(item, index) in list"
                  :key="index"
                  @click="fnView(item)"
                >
                  <div class="party_tag">
                    <div class="left">
                      <div
                        class="pt_tag logo"
                        style="--pt-txt-color: #fff;"
                        :style="{
                          '--pt-bg-color': $ConstCode.getCategoryById(
                            item.categoryId
                          ).color
                        }"
                      >
                        {{ item.categoryNm }}
                      </div>
                      <div class="pt_tag normal nb">
                        {{ `${item.totalMemberCnt}/${item.recruitNum}` }}
                      </div>
                      <div
                        class="pt_tag"
                        :class="{ new: Number(item.hasBadge) === 1 }"
                        v-if="
                          item.status !== '종료' && Number(item.hasBadge) === 1
                        "
                      ></div>
                    </div>
                    <div class="right">
                      <div class="btn_wrap">
                        <!--                    TODO] class명이 ext_rd 연장신청 ext_gr 연장취소-->
                        <button
                          class="ext_bl"
                          @click.stop.prevent="fnModalAlertRegenerate(item)"
                          v-if="
                            item.autoExtension === 'N' && item.status === '종료'
                          "
                        >
                          재등록
                        </button>
                        <button
                          class="ext_gr"
                          v-else-if="
                            item.autoExtension === 'Y' && item.status !== '종료'
                          "
                          @click.stop="fnRequestExtensionCancel(item, index)"
                        >
                          연장취소
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="party_ttl">{{ item.title }}</div>
                  <div class="party_con">
                    <div class="left">
                      <div class="party_info">
                        <dl>
                          <dt>정산 기간 :</dt>
                          <dd v-if="item.unlimitedCheckYn === 'N'">
                            {{
                              `${$dayjs(item.regDt)
                                .add(2, 'd')
                                .format('YYYY.MM.DD')} ~ ${$dayjs(item.useDt)
                                .add(1, 'd')
                                .format('YYYY.MM.DD')}`
                            }}
                          </dd>
                          <dd v-else>표시 없음</dd>
                        </dl>
                      </div>
                      <div class="party_info">
                        <dl>
                          <dt>상태 :</dt>
                          <dd>{{ item.status }}</dd>
                        </dl>
                      </div>
                    </div>
                    <div class="right">
                      <div class="party_price">
                        <span class="value">{{
                          $Util.formatNumber2(
                            item.acntTransferYn === 'N'
                              ? Number(item.usePeriod) * Number(item.dailyPrice)
                              : Number(item.dailyPrice),
                            { unit: '' }
                          )
                        }}</span
                        ><!--띄어쓰기-->
                        <span class="unit">원</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="empty_wrap" v-else>
              <div class="empty">
                <div>
                  아직 생성한 파티가 없습니다.
                </div>
              </div>
              <button class="btn" @click="fnWrite">파티 생성하기</button>
            </div>
            <div class="page_btn">
              <div class="btn_wrap">
                <button class="btn" @click="fnWrite">
                  파티 생성하기</button
                ><!--버튼 중복: 웹 분기점 디자인 적용-->
              </div>
            </div>
            <observe-visible
              :key="initCnt"
              :page="page"
              :limit="limit"
              :totalCnt="totalCnt"
              @fetch="fnListFunction"
            />
          </div>
        </div>
      </div>
    </div>
    <nice
      :id="'formNice13'"
      :m="niceForm.m"
      :encode-data="niceForm.encodeData"
      :call-type="niceForm.callType"
      :rtn-url="rtnUrl"
      :fail-rtn-url="failRtnUrl"
      :callback="fnCallback"
    />
  </main>
</template>

<script>
import { componentState } from './index.js'
import SearchConditions from '@/components/views/my-page/SearchConditions'

export default {
  name: 'my-page-created-party-index',
  components: { SearchConditions },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
