import {
  computed,
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  onActivated
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { fnModalAlert } from '@/components/common/modal/alert'
import { CommonFunction } from '@/common/CommonFunction'
import { niceForm } from '@/common/form/NiceForm'

const INIT_PAGE = 0
const INIT_LIMIT = 20
const INIT_TOTAL_CNT = 0

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const status = (route.query.status && JSON.parse(route.query.status)) || []
  const autoExtension = route.query.autoExtension || null
  const getConditionList = () => {
    if (proxy.$Util.isEmpty(autoExtension) && proxy.$Util.isEmpty(status)) {
      return proxy.$ConstCode.CREATED_PARTY_CONDITION.map(c => ({
        ...c,
        value: false
      }))
    } else {
      return proxy.$ConstCode.CREATED_PARTY_CONDITION.map(c => {
        if (c.status) {
          return {
            ...c,
            value: status.find(s => s === c.status) ? true : false
          }
        }
      })
    }
  }
  const state = reactive({
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    searchWord: route.query.search || '',
    conOpen: (route.query.open && JSON.parse(route.query.open)) || false,
    sort: route.query.sort || 'MODDT',
    order: route.query.order || 'DESC',
    totalCnt: INIT_TOTAL_CNT,
    autoExtension,
    status,
    conditionsList: getConditionList(),
    list: [],
    initCnt: 0,
    params: computed({
      get: () => {
        return {
          startNum: state.list.length + 1,
          endNum: (state.page + 1) * state.limit,
          searchWord: state.searchWord,
          sort: state.sort,
          order: state.order,
          autoExtension: state.autoExtension,
          status: state.status
        }
      }
    }),
    listScrollTop: 0,
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`
  })
  const { fnWrite, fnUpdateAuthType } = CommonFunction(state)
  const fnListFunction = async () => {
    const res = await proxy.$MyPageSvc.postCreatePartyList(state.params)
    if (res.resultCode === '0000') {
      state.page++
      state.totalCnt = res.totalCnt
      state.list = state.list.concat(res.list)
    }
  }
  const fnMove = async () => {
    await router.replace({
      path: '/my-page/joined-party'
    })
  }
  const fnSearch = () => {
    route.query.open = state.conOpen
    const r = proxy.$Util.getRandomString()
    fnReset()
    state.initCnt++
    router.replace({
      name: 'my-page-created-party-index',
      query: { ...route.query, search: state.searchWord, r }
    })
  }
  const fnReset = () => {
    state.page = INIT_PAGE
    state.limit = INIT_LIMIT
    state.totalCnt = INIT_TOTAL_CNT
    state.list = []
  }

  const fnModalAlertRegenerate = async item => {
    const payload = {
      component: proxy.$modalAlertNames.PARTY_REGENERATE,
      data: item,
      callback: async () => {
        await fnSearch()
      }
    }
    await fnModalAlert(payload)
  }

  const inputSearchWord = e => {
    state.searchWord = e.target.value
  }

  const fnUpdateConditionsList = conditionsList => {
    state.conditionsList = conditionsList
    state.status = state.conditionsList.reduce(
      (pv, cv) => (cv.value && cv.status ? [...pv, cv.status] : [...pv]),
      []
    )
  }

  const fnUpdateOnOffConditions = open => {
    state.conOpen = open
  }

  const fnUpdateAutoExtension = autoExtension => {
    state.autoExtension = autoExtension
  }

  const fnView = async item => {
    const pageCon = document.querySelector('#page_con')
    if (pageCon) {
      state.listScrollTop = pageCon.scrollTop
    }
    await router.push({
      path: '/my-page/created-party/view',
      query: { partyNo: item.partyNo }
    })
  }
  const fnCallback = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
      await router.push({ path: '/my-info/phone-auth' })
      // await fnMyInfo()
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }
  const fnRequestExtension = async (item, index, type = 0) => {
    if (type === 0) {
      if (!confirm('파티를 자동 연장 신청 하시겠습니까?')) return
    }
    const res = await proxy.$MyPageSvc.postPartyAutoExtension({
      partyNo: item.partyNo
    })
    alert(res.resultMsg)
    if (res.resultCode === '0000') {
      item.autoExtensionType = '1'
      state.list[index] = item
    }
  }
  const fnRequestExtensionCancel = async item => {
    if (
      !confirm(
        '자동연장 설정을 해제 하시겠습니까?\n해지 후 재설정은 불가능 합니다.'
      )
    )
      return
    const res = await proxy.$MyPageSvc.postPartyAutoExtensionCancel({
      partyNo: item.partyNo
    })
    let resultMsg = res.resultMsg
    if (res.resultCode === '0000') {
      resultMsg = '해제되었습니다.\n이용기간 만료 후 해당파티는 마감됩니다.'
      // resultMsg = `해제되었습니다.\n${proxy
      //   .$dayjs(item.useDt).subtract(1, 'd')
      //   .format('YYYY년 MM월DD일')} 파티가 종료됩니다.`
      await fnSearch()
    }
    alert(resultMsg)
  }
  /** watch **/
  watch(
    () => state.sort,
    async () => {
      route.query.sort = state.sort
      await fnSearch()
    }
  )

  watch(
    () => state.autoExtension,
    async () => {
      route.query.autoExtension = state.autoExtension
      await fnSearch()
    }
  )

  watch(
    () => state.status,
    async () => {
      route.query.status = JSON.stringify(state.status)
      await fnSearch()
    }
  )

  watch(
    () => state.order,
    async () => {
      route.query.order = state.order
      await fnSearch()
    }
  )

  onActivated(async () => {
    if (!proxy.$Util.isEmpty(state.listScrollTop) && state.listScrollTop > 0) {
      document.querySelector('#page_con').scrollTop = state.listScrollTop
      state.listScrollTop = 0
    } else {
      fnSearch()
    }
  })

  return {
    ...toRefs(state),
    fnListFunction,
    fnSearch,
    fnModalAlertRegenerate,
    fnView,
    fnWrite,
    fnMove,
    fnCallback,
    fnRequestExtension,
    fnRequestExtensionCancel,
    inputSearchWord,
    fnUpdateConditionsList,
    fnUpdateOnOffConditions,
    fnUpdateAutoExtension
  }
}
